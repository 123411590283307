
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import roles from "@/core/data/roles";
import { IRole } from "@/core/data/roles";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
  name: "role-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    AddCustomerModal,
  },
  setup() {
    const checkedRoles = ref([]);
    const tableHeader = ref([
      {
        name: "Nama Role",
        key: "name",
        sortable: true,
      },
      {
        name: "Digunakan",
        key: "userCount",
        sortable: true,
      },
      {
        name: "Jml Permission",
        key: "permissionCount",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IRole>>(roles);
    const initCustomers = ref<Array<IRole>>([]);
    const store = useStore();

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Daftar Peran", ["Master Data"]);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      store.commit(Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG, {
        toolbar: { display: false },
      });
      const ktWrapper = window.document.getElementById("kt_wrapper");
      if (ktWrapper) ktWrapper.style.paddingTop = "55px";
    });

    const deleteFewCustomers = () => {
      checkedRoles.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedRoles.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IRole> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedRoles,
      deleteFewCustomers,
    };
  },
});
