interface IRole {
  id: number;
  name: string;
  description: string;
  status: boolean;
  userCount: number;
  permissionCount: number;
}

const roles: Array<IRole> = [
  {
    id: 1,
    name: "Superadmin",
    description: "Deskripsi",
    userCount: 3,
    permissionCount: 40,
    status: false,
  },
  {
    id: 2,
    name: "Admin Tenant",
    description: "Deskripsi",
    userCount: 10,
    permissionCount: 20,
    status: false,
  },
  {
    id: 3,
    name: "Manajer Proyek",
    description: "Deskripsi",
    userCount: 120,
    permissionCount: 10,
    status: false,
  },
  {
    id: 4,
    name: "Staff",
    description: "Deskripsi",
    userCount: 150,
    permissionCount: 5,
    status: false,
  },
  {
    id: 5,
    name: "Finance",
    description: "Deskripsi",
    userCount: 30,
    permissionCount: 5,
    status: false,
  },
];
export { IRole };

export default roles;
